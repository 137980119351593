import React from "react"
import cn from "classnames"
import sal from "sal.js"
import "sal.js/dist/sal.css"

import ImageCloudinary from "../ImageCloudinary"
import * as styles from "./portfolio.module.css"
import TransitionLink from "../TransitionLink"
import useInViewPort from "../../useInViewPort"
import Sidebar from "./sections/Sidebar"
import clsx from "clsx"
import {
  HideBetween,
  HideDuring,
  HideOn,
  HideScroll,
} from "react-hide-on-scroll"

const rowStyles = [styles.rowEnd, styles.rowStart]
const content = require("../../content/portfolio")

export default function PortfolioPage() {
  const [translateY, setTranslateY] = React.useState("5%")
  const [elementScroll, setElemetScroll] = React.useState(0)

  const [nodeRef, isVisible] = useInViewPort({ triggerOnce: false })

  // Scroll Effect
  React.useEffect(() => {
    sal()
    function onScroll() {
      const scrollPercent =
        document.documentElement.scrollTop /
        (document.documentElement.scrollHeight - window.innerHeight)

      const maxHeight = 15
      const newTranslateY =
        scrollPercent < 0.5
          ? 5 + scrollPercent * 30
          : maxHeight - (scrollPercent - 0.5) * 80

      setTranslateY(`${newTranslateY}%`)
    }
    setElemetScroll(document.documentElement.scrollHeight)

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  const animation = {
    "data-sal": "slide-up",
    "data-sal-delay": "150",
    "data-sal-duration": "350",
    "data-sal-easing": "ease-in",
  }

  return (
    <div className={styles.projects} id="wrapper">
      <div {...animation}>
        <div
          ref={nodeRef}
          className={`${styles.mainText} ${isVisible ? "" : styles.hidden}`}
        >
          {content.description}
        </div>
      </div>

      <HideOn divID="endProjectContainer" showOnPageInit={false}>
        <Sidebar elementScroll={elementScroll} />
      </HideOn>

      <div className={styles.rowsContainer}>
        {content.projects.map((project, index) => (
          <div
            name={`section${index}`}
            key={index}
            className={clsx(cn([styles.row, rowStyles[index % 2]]), {
              [styles.tall]: project.image.includes("?tl"),
            })}
            {...animation}
          >
            <div className={styles.content}>
              <TransitionLink
                className={styles.images}
                key={index}
                cover
                direction="up"
                bg={project.backgroundColor}
                to={`/project/${index}`}
              >
                <ImageCloudinary path={project.image} />
              </TransitionLink>

              <div className={styles.shadow3}>
                <img
                  src={project.aspectRatio}
                  alt=""
                  style={{
                    backgroundColor: project.backgroundColor,
                    transform: `translate3d(6%, ${translateY}, 0)`,
                  }}
                />
              </div>
            </div>
            <div className={styles.titleMobile}>{project.title}</div>
          </div>
          // {/* </Element> */}
        ))}
      </div>
      <div id="endProjectContainer"></div>
    </div>
  )
}
