import React from "react"

import Layout from "../components/Layout"
import PortfolioPage from "../components/PortfolioPage/portfolio"

export default function Portfolio() {
  return (
    <section style={{backgroundColor:'white'}}>
      <PortfolioPage />
    </section>
  )
}
