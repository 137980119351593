// extracted by mini-css-extract-plugin
export var content = "portfolio-module--content--d4539";
export var contentElement = "portfolio-module--contentElement--5e062";
export var hidden = "portfolio-module--hidden--db15b";
export var images = "portfolio-module--images--a24f7";
export var mainText = "portfolio-module--mainText--eeeb6";
export var projects = "portfolio-module--projects--ae2d3";
export var row = "portfolio-module--row--9241f";
export var rowEnd = "portfolio-module--rowEnd--2890c";
export var rowStart = "portfolio-module--rowStart--d02be";
export var rowsContainer = "portfolio-module--rowsContainer--280e1";
export var shadow3 = "portfolio-module--shadow3--e14ae";
export var tall = "portfolio-module--tall--7e328";
export var titleMobile = "portfolio-module--titleMobile--2b9d2";