import React, { Component } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll';
import * as styles from "./sidebar.module.css"

const content = require("../../../../content/portfolio")

export default class Sidebar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <div className={styles.links}>
        {content.projects.map((project, index) => (
          <div className={styles.link} key={index}>
            <Link
              to={`section${index}`}
              activeClass={styles.active}
              spy={true}
              smooth={true}
              hashSpy={true}
              offset={-250}
              >{project.title}
            </Link>
          </div>
        ))}
      </div>
    );
  }
}